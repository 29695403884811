var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"rounded":"sm","show":_vm.isLoading,"no-wrap":""}}),_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"pb-50"},[_c('h5',[_vm._v("Создать приглашение на регистрацию")])]),_c('b-card-body',[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-select',{staticClass:"w-25",attrs:{"options":_vm.roleOptions,"placeholder":"Выберите роль для создания приглашения"},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_c('span',{staticClass:"text-truncate overflow-hidden"},[_vm._v(" "+_vm._s(label)+" ")])]}}]),model:{value:(_vm.newInviteRole),callback:function ($$v) {_vm.newInviteRole=$$v},expression:"newInviteRole"}}),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-1",attrs:{"variant":"primary","disabled":_vm.newInviteRole === null},on:{"click":_vm.addInvite}},[_vm._v(" Создать инвайт ")])],1)])],1),_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"pb-50"},[_c('h5',[_vm._v("Активные инвайты")])]),_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v("Показывать")]),_c('v-select',{staticClass:"per-page-selector d-inline-block mx-50",attrs:{"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('label',[_vm._v("инвайтов")])],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{staticClass:"invite-filter-select",attrs:{"options":_vm.roleOptions,"placeholder":"Выберите роль"},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_c('span',{staticClass:"text-truncate overflow-hidden"},[_vm._v(" "+_vm._s(label)+" ")])]}}]),model:{value:(_vm.roleFilter),callback:function ($$v) {_vm.roleFilter=$$v},expression:"roleFilter"}})],1)],1)],1),_c('b-table',{ref:"refInviteListTable",staticClass:"position-relative",attrs:{"items":_vm.invitesFormatted,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"Активные инвайты не найдены","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"cell(group.title)",fn:function(data){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(link)",fn:function(data){return [_c('span',{staticClass:"text-nowrap",attrs:{"id":("invite-row-" + (data.item.id) + "-link")}},[_vm._v(" "+_vm._s(data.value)+" ")]),_c('b-tooltip',{attrs:{"title":data.value,"target":("invite-row-" + (data.item.id) + "-link"),"delay":{
            show: 500,
            hide: 100,
          },"custom-class":"link-tooltip"}})]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"mx-1 cursor-pointer",attrs:{"id":("invite-row-" + (data.item.id) + "-copy-icon"),"icon":"CopyIcon","size":"16"},on:{"click":function($event){return _vm.copyLink(data.item.link)}}}),_c('feather-icon',{staticClass:"mx-1 cursor-pointer",attrs:{"id":("invite-row-" + (data.item.id) + "-trash-icon"),"icon":"TrashIcon","size":"16"},on:{"click":function($event){return _vm.showDeleteInviteModal(data.item.id)}}})],1)]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"\n            d-flex\n            align-items-center\n            justify-content-center justify-content-sm-start\n          ",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v(" Показано от "+_vm._s(_vm.dataMeta.from)+" до "+_vm._s(_vm.dataMeta.to)+" из "+_vm._s(_vm.dataMeta.of)+" инвайтов ")])]),_c('b-col',{staticClass:"\n            d-flex\n            align-items-center\n            justify-content-center justify-content-sm-end\n          ",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalInvites,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }