<template>
  <div>
    <b-overlay rounded="sm" :show="isLoading" no-wrap />

    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>Создать приглашение на регистрацию</h5>
      </b-card-header>

      <b-card-body>
        <div class="d-flex align-items-center">
          <v-select
            v-model="newInviteRole"
            :options="roleOptions"
            class="w-25"
            placeholder="Выберите роль для создания приглашения"
          >
            <template #selected-option="{ label }">
              <span class="text-truncate overflow-hidden">
                {{ label }}
              </span>
            </template>
          </v-select>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class="ml-1"
            variant="primary"
            :disabled="newInviteRole === null"
            @click="addInvite"
          >
            Создать инвайт
          </b-button>
        </div>
      </b-card-body>
    </b-card>

    <b-card no-body class="mb-0">
      <b-card-header class="pb-50">
        <h5>Активные инвайты</h5>
      </b-card-header>

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Показывать</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>инвайтов</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <v-select
              v-model="roleFilter"
              :options="roleOptions"
              class="invite-filter-select"
              placeholder="Выберите роль"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refInviteListTable"
        class="position-relative"
        :items="invitesFormatted"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Активные инвайты не найдены"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Роль -->
        <template #cell(group.title)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <!-- Ссылка -->
        <template #cell(link)="data">
          <span :id="`invite-row-${data.item.id}-link`" class="text-nowrap">
            {{ data.value }}
          </span>

          <b-tooltip
            :title="data.value"
            :target="`invite-row-${data.item.id}-link`"
            :delay="{
              show: 500,
              hide: 100,
            }"
            custom-class="link-tooltip"
          />
        </template>

        <!-- Действия -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`invite-row-${data.item.id}-copy-icon`"
              icon="CopyIcon"
              size="16"
              class="mx-1 cursor-pointer"
              @click="copyLink(data.item.link)"
            />
            <!-- <b-tooltip
              title="Копировать ссылку"
              :target="`invite-row-${data.item.id}-copy-icon`"
            /> -->

            <feather-icon
              :id="`invite-row-${data.item.id}-trash-icon`"
              icon="TrashIcon"
              size="16"
              class="mx-1 cursor-pointer"
              @click="showDeleteInviteModal(data.item.id)"
            />
            <!-- <b-tooltip
              title="Удалить инвайт"
              :target="`invite-row-${data.item.id}-trash-icon`"
            /> -->
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">
              Показано от {{ dataMeta.from }} до {{ dataMeta.to }} из
              {{ dataMeta.of }} инвайтов
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalInvites"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BTable,
  BTooltip,
  BPagination,
  BOverlay,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import { paginateArray, sortCompare } from '@/helpers/utils'
import { showMessage, showError } from '@/helpers/notifications'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BTable,
    BPagination,
    BOverlay,
    BTooltip,
    BButton,

    vSelect,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    tableColumns: [
      { key: 'group.title', label: 'Роль', sortable: true },
      {
        key: 'link',
        label: 'Уникальная ссылка',
        sortable: true,
        class: 'invite-col',
      },
      { key: 'actions', label: 'Действия' },
    ],
    perPage: 10,
    totalInvites: 0,
    currentPage: 1,
    perPageOptions: [10, 25, 50, 100],
    searchQuery: '',
    sortBy: 'id',
    isSortDirDesc: true,

    roleFilter: null,
    newInviteRole: null,

    isLoading: false,
  }),
  computed: {
    ...mapGetters({
      invites: 'getInvites',
      userGroups: 'getUserGroups',
    }),
    dataMeta() {
      const localItemsCount = this.$refs.refInviteListTable
        ? this.$refs.refInviteListTable.localItems.length
        : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalInvites,
      }
    },
    roleOptions() {
      const options = []

      this.userGroups.forEach(group =>
        options.push({
          label: group.title,
          value: group.id,
        }),
      )

      return options
    },
    invitesFormatted() {
      const invites = this.invites.slice()
      const roleFilter = this.roleFilter ? this.roleFilter.value : null

      const filteredData = invites.filter(
        invite =>
          invite.group.id ===
          (roleFilter !== null ? roleFilter : invite.group.id),
      )

      const sortedData = filteredData.sort(sortCompare(this.sortBy))

      if (this.isSortDirDesc) sortedData.reverse()

      const paginatedData = paginateArray(
        sortedData,
        this.perPage,
        this.currentPage,
      )

      this.totalInvites = sortedData.length

      return paginatedData
    },
  },
  async beforeCreate() {
    await this.$store.dispatch('fetchUserGroups')
    await this.$store.dispatch('fetchInvites')
  },
  methods: {
    copyLink(link) {
      this.$copyText(link).then(
        () => {
          showMessage('Ссылка скопирована')
        },
        () => {
          showError('Произошла ошибка при копировании')
        },
      )
    },
    async showDeleteInviteModal(inviteId) {
      const modalStatus = await this.$bvModal.msgBoxConfirm(
        'Вы действительно хотите удалить это приглашение?',
        {
          title: 'Требуется подтверждение',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Подтвердить',
          cancelTitle: 'Отмена',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        },
      )

      if (modalStatus) await this.deleteInvite(inviteId)
    },
    async addInvite() {
      if (this.newInviteRole === null) return

      this.isLoading = true

      const inviteData = { user_group: this.newInviteRole.value }
      await this.$store.dispatch('addInvite', inviteData)

      this.isLoading = false
    },
    async deleteInvite(inviteId) {
      this.isLoading = true

      await this.$store.dispatch('deleteInvite', inviteId)

      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.per-page-selector {
  width: 90px;
}

.invite-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/base/bootstrap-extended/include';
@import '@core/scss/base/components/variables-dark';

.dark-layout {
  .b-overlay {
    .bg-light {
      background-color: $theme-dark-body-bg !important;
    }
  }
}

.invite-col {
  max-width: 100px !important;
  width: 100% !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
